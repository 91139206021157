// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities/api";

$color-font-wb: #0D0D0D;
$color-font-bw: #FEFEFE;
$color-contrast-white: #FFF;
$color-contrast-black: #000;
$color-contrast-yellow: #F6FF02;
$color-deep-blue: rgb(1, 18, 70);
$color-light-blue: #1070DE;
$color-pink: rgba(237, 41, 134, 1);


@font-face {
    font-family: "degular";
    src: url("https://use.typekit.net/af/11ba66/00000000000000007735c86d/30/l?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/11ba66/00000000000000007735c86d/30/d?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/11ba66/00000000000000007735c86d/30/a?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=n5&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
}

@font-face {
    font-family: "degular";
    src: url("https://use.typekit.net/af/f5dfa7/00000000000000007735c86f/30/l?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/f5dfa7/00000000000000007735c86f/30/d?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/f5dfa7/00000000000000007735c86f/30/a?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=i5&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 500;
    font-stretch: normal;
}

@font-face {
    font-family: "degular";
    src: url("https://use.typekit.net/af/36a2f5/00000000000000007735c877/30/l?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/36a2f5/00000000000000007735c877/30/d?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/36a2f5/00000000000000007735c877/30/a?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "degular";
    src: url("https://use.typekit.net/af/29fa5e/00000000000000007735c87b/30/l?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/29fa5e/00000000000000007735c87b/30/d?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/29fa5e/00000000000000007735c87b/30/a?primer=2a64b7354accbfccf091944358d6cba32f300a9a753ae564cfcf2ef3ec0bce66&fvd=i7&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
}

body {
    font-family: "degular", sans-serif;
    ;
}

.page {
    min-height: 100vh;
    color: $color-font-wb;
    background: url(logo.svg) no-repeat $color-font-bw;
    background-size: 86%;
    background-position: left 465% bottom 65%;
    font-size: 18px;

    .error {
        font-size: 85%;
        padding: 10px;
    }

    &.page-employee {
        background-image: url(logo-alt.svg);
    }

    button {
        color: $color-deep-blue;
        padding: 0;
    }

    a {
        color: $color-font-wb;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &.font-bigger {
        font-size: 22px;

        h1 {
            font-size: 38px;
        }

        .services h1 {
            font-size: 50px;
        }

        .nav {
            font-size: 22px;

            h5 {
                font-size: 22px;
            }
        }

        &.login .btn-login svg {
            width: 151px;
        }
    }

    &.font-the-biggest {
        font-size: 26px;

        h1 {
            font-size: 42px;
        }

        .services h1 {
            font-size: 56px;
        }

        .nav {
            font-size: 26px;

            h5 {
                font-size: 26px;
            }
        }

        &.login .btn-login svg {
            width: 166px;
        }
    }

    &.tpl-bw {
        color: $color-contrast-white;
        background-image: none;
        background-color: $color-contrast-black;

        button {
            color: $color-contrast-white;
        }

        a {
            color: $color-contrast-white;
        }

        section.services .ico svg {
            .do-cls-1 {
                fill: $color-contrast-black;
                stroke: $color-contrast-black;
            }

            .do-cls-2 {
                fill: $color-contrast-white;
                stroke: $color-contrast-white;
            }

            .sm-cls-1 {
                fill: $color-contrast-white;
                stroke: $color-contrast-white;
            }

            .sm-cls-2 {
                stroke: $color-contrast-black;
            }

            .el-cls-1 {
                fill: $color-contrast-white;
            }

            .el-cls-2 {
                stroke: $color-contrast-black;
            }

            .lib-cls-1 {
                fill: $color-contrast-white;
            }

            .lib-cls-2 {
                stroke: $color-contrast-black;
            }

            .em-cls-1 {
                fill: $color-contrast-white;
            }

            .em-cls-2 {
                stroke: $color-contrast-black;
            }

            .mt-cls-1 {
                fill: $color-contrast-white;
            }

            .mt-cls-2 {
                stroke: $color-contrast-black;
            }

            .kb-cls-1 {
                fill: $color-contrast-white;
            }

            .kb-cls-2 {
                stroke: $color-contrast-black;
            }
        }

        .nav .logo {

            .cls-1,
            .cls-2 {
                fill: $color-contrast-white;
            }
        }

        .nav.top .row,
        .nav.container.bottom .row {

            &::after,
            &::before {
                border-color: $color-contrast-white;
            }
        }

        &.login .btn-login {
            border-color: $color-contrast-white;
            background: $color-contrast-white;

            svg rect {
                fill: $color-contrast-black
            }
        }

        h1,
        h1.featured,
        .services.employee h1,
        .services.employee h1.featured {
            color: $color-contrast-white;
        }

        .nav .lang-switcher li {
            border-color: $color-contrast-white;
        }

        .nav .contrast-switcher li .btn-tpl-bw {
            border-color: $color-contrast-white;
        }

        .col.id {
            background-color: $color-contrast-white;

            svg {

                .cls-3,
                .cls-4 {
                    fill: $color-contrast-black;
                }
            }
        }
    }

    &.tpl-yb {
        color: $color-contrast-black;
        background-image: none;
        background-color: $color-contrast-yellow;

        button {
            color: $color-contrast-black;
        }

        .nav .logo {

            .cls-1,
            .cls-2 {
                fill: $color-contrast-black;
            }
        }

        .nav.top .row,
        .nav.container.bottom .row {

            &::after,
            &::before {
                border-color: $color-contrast-black;
            }
        }

        h1,
        h1.featured,
        .services.employee h1,
        .services.employee h1.featured {
            color: $color-contrast-black;
        }

        &.login .btn-login {
            border-color: $color-contrast-black;
            background: $color-contrast-black;

            svg rect,
            svg path {
                fill: $color-contrast-yellow;
            }
        }

        .col.id {
            background-color: $color-contrast-black;

            svg {

                .cls-3,
                .cls-4 {
                    fill: $color-contrast-yellow;
                }
            }
        }

        section.services .ico svg {
            .do-cls-1 {
                fill: $color-contrast-yellow;
                stroke: $color-contrast-yellow;
            }

            .do-cls-2 {
                fill: $color-contrast-black;
                stroke: $color-contrast-black;
            }

            .sm-cls-1 {
                fill: $color-contrast-black;
                stroke: $color-contrast-black;
            }

            .sm-cls-2 {
                stroke: $color-contrast-yellow;
            }

            .el-cls-1 {
                fill: $color-contrast-black;
            }

            .el-cls-2 {
                stroke: $color-contrast-yellow;
            }

            .lib-cls-1 {
                fill: $color-contrast-black;
            }

            .lib-cls-2 {
                stroke: $color-contrast-yellow;
            }

            .em-cls-1 {
                fill: $color-contrast-black;
            }

            .em-cls-2 {
                stroke: $color-contrast-yellow;
            }

            .mt-cls-1 {
                fill: $color-contrast-black;
            }

            .mt-cls-2 {
                stroke: $color-contrast-yellow;
            }

            .kb-cls-1 {
                fill: $color-contrast-black;
            }

            .kb-cls-2 {
                stroke: $color-contrast-yellow;
            }
        }
    }

    &.tpl-by {
        color: $color-contrast-yellow;
        background-image: none;
        background-color: $color-contrast-black;

        button {
            color: $color-contrast-yellow;
        }

        a {
            color: $color-contrast-yellow;
        }

        .services .ico svg {
            .cls-1 {
                fill: $color-contrast-black;
            }

            .cls-2 {
                fill: $color-contrast-yellow;
            }
        }

        .nav .logo {

            .cls-1,
            .cls-2 {
                fill: $color-contrast-yellow;
            }
        }

        .nav.top .row,
        .nav.container.bottom .row {

            &::after,
            &::before {
                border-color: $color-contrast-yellow;
            }
        }

        .nav .lang-switcher li {
            border-color: $color-contrast-yellow;
        }

        .nav .contrast-switcher li .btn-tpl-by {
            border-color: $color-contrast-yellow;
        }

        h1,
        h1.featured,
        .services.employee h1,
        .services.employee h1.featured {
            color: $color-contrast-yellow;
        }

        &.login .btn-login {
            border-color: $color-contrast-yellow;
            background: $color-contrast-yellow;

            svg rect,
            svg path {
                fill: $color-contrast-black;
            }
        }

        .col.id {
            background-color: $color-contrast-yellow;

            svg {

                .cls-3,
                .cls-4 {
                    fill: $color-contrast-black;
                }
            }
        }

        section.services .ico svg {
            .do-cls-1 {
                fill: $color-contrast-black;
                stroke: $color-contrast-black;
            }

            .do-cls-2 {
                fill: $color-contrast-yellow;
                stroke: $color-contrast-black;
            }

            .sm-cls-1 {
                fill: $color-contrast-yellow;
                stroke: $color-contrast-black;
            }

            .sm-cls-2 {
                stroke: $color-contrast-black;
            }

            .el-cls-1 {
                fill: $color-contrast-yellow;
            }

            .el-cls-2 {
                stroke: $color-contrast-black;
            }

            .lib-cls-1 {
                fill: $color-contrast-yellow;
            }

            .lib-cls-2 {
                stroke: $color-contrast-black;
            }

            .em-cls-1 {
                fill: $color-contrast-yellow;
            }

            .em-cls-2 {
                stroke: $color-contrast-black;
            }

            .mt-cls-1 {
                fill: $color-contrast-yellow;
            }

            .mt-cls-2 {
                stroke: $color-contrast-black;
            }

            .kb-cls-1 {
                fill: $color-contrast-yellow;
            }

            .kb-cls-2 {
                stroke: $color-contrast-black;
            }
        }
    }

    &.login {
        background-image: none;

        .logo svg {
            height: 77px;
        }

        .auth {
            margin: 15% auto 40%;
            max-width: 340px;
        }

        .btn-login {
            display: block;
            border: solid 1px #DEE4ED;
            border-radius: 8px;
            background: none;
            width: 100%;
            padding: 10px 0;

            svg {
                width: 136px;
            }
        }

        .nav {
            &.top .row {
                justify-content: space-evenly !important;

                &::after {
                    margin-top: 18px;
                    margin-bottom: 18px;
                }
            }

            &.bottom .row::before {
                flex-basis: 100%;
                display: block;
                content: "";
                border-bottom: 2px solid $color-deep-blue;
            }
        }


        .id {
            display: none;
        }

        .top {
            .font-switcher {
                flex-basis: 22%;
            }

            .contrast-switcher {
                flex-basis: 34%;
            }

            .font-switcher {
                flex-basis: 22%;
            }
        }

        @include media-breakpoint-up(md) {
            .id {
                display: flex;
                min-height: 100vh;
                background-color: $color-deep-blue;
                align-items: center;
                justify-content: center;

                svg {
                    width: 60%;
                }
            }

            .logo {
                display: none;
            }

            .bottom .font-switcher {
                display: none;
            }

            .bottom .contrast-switcher {
                display: none;
            }

            .nav {
                &.top .row {
                    &::after {
                        display: none;
                        ;
                    }
                }

                &.bottom .row::before {
                    display: none;
                    ;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .nav {
                margin-top: 47px;

                .logo {
                    text-align: center;
                }
            }

            .logo {
                width: 100%;
            }

            .top .font-switcher {
                display: none;
            }

            .top .contrast-switcher {
                display: none;
            }
        }

        @include media-breakpoint-down(lg) {
            .top .lang-switcher {
                display: none;
            }

            .bottom .lang-switcher {
                display: block;

            }

            .bottom .row {
                justify-content: center !important;
            }
        }

        @include media-breakpoint-up(lg) {
            .bottom .lang-switcher {
                display: none;
            }
        }

        @media only screen and (max-width: 410px) {
            .logo {
                height: 65px;
            }

            .bottom .font-switcher {
                flex-basis: 40%;
            }

            .bottom .lang-switcher {
                flex-basis: 100%;
                text-align: center;
            }

            .bottom .contrast-switcher {
                flex-basis: 50%;
            }
        }

        @media only screen and (max-width: 360px) {
            .bottom .font-switcher {
                text-align: center;
                flex-basis: 100%;
            }


            .bottom .contrast-switcher {
                text-align: center;
                flex-basis: 100%;
            }
        }

    }

    &.std {
        @include media-breakpoint-down(lg) {
            .logo {
                flex-basis: 76%;
                margin-bottom: 4px;
            }

            .top .lang-switcher {
                order: 2;
                flex-basis: 24%;
            }

            .top .font-switcher {
                order: 4;
                flex-basis: 40%;
            }

            .top .contrast-switcher {
                order: 5;
                flex-basis: 60%;
            }

            .top .row::after {
                order: 3;
            }
        }

        @include media-breakpoint-down(md) {
            .top .lang-switcher {
                h5 {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .logo {
                flex-basis: 56%;

                svg {
                    min-height: 50px;
                }
            }

            r .top .lang-switcher {
                flex-basis: 44%;
                text-align: center;
            }
        }

        @include media-breakpoint-up(sm) {
            .logo svg {
                width: 315px;
            }
        }

        @include media-breakpoint-up(lg) {
            .logo {
                margin-right: 126px;
            }
        }
    }

    .nav {
        font-size: 18px;
        font-weight: 900;
        line-height: 25px;
        vertical-align: middle;
        width: 100%;
        margin-top: 45px;
        text-align: left;

        h5 {
            font-size: 18px;
            font-weight: normal;
            margin-bottom: 25px;
        }

        button {
            background: none;
            border: none;
        }

        ul {
            padding-left: 0px;

            li {
                font-weight: bold;
                display: inline-block;
            }
        }

        .font-switcher {
            margin-top: 20px;
            flex-basis: 15%;
            min-width: 110px;
            padding: 0;

            button {
                margin-right: 6px;
                margin-top: 5px;
            }

            ul li {
                &:last-child {
                    button {
                        margin-right: 0px;
                    }
                }
            }
        }

        .contrast-switcher {
            margin-top: 22px;
            flex-basis: 20%;
            min-width: 200px;
            padding: 0;

            button {
                border: 2px solid $color-contrast-black;
                width: 34px;
                height: 34px;
                margin-right: 16px;
                text-align: center;
            }

            .btn-tpl-wb {
                color: $color-contrast-black;
                background-color: $color-contrast-white;
            }

            .btn-tpl-bw {
                color: $color-contrast-white;
                background-color: $color-contrast-black;
            }

            .btn-tpl-yb {
                color: $color-contrast-black;
                background-color: $color-contrast-yellow;
            }

            .btn-tpl-by {
                color: $color-contrast-yellow;
                background-color: $color-contrast-black;
            }
        }

        .lang-switcher {
            margin-top: 22px;
            padding: 0;
            flex-basis: 6%;
            min-width: 90px;
            padding: 0;

            li {
                border-right: 2px solid $color-contrast-black;

                &:last-child {
                    border: none;
                }
            }

            button {
                width: 34px;
                height: 34px;
                margin: 0 5px;
            }
        }

        &.top .row::after {
            flex-basis: 100%;
            display: block;
            content: "";
            border-bottom: 2px solid $color-deep-blue;
            max-width: 985px;
        }
    }

    .services {
        margin: 50px 0 150px 0;

        h1 {
            font-size: 48px;
        }

        ul {
            padding: 0;

            li {
                font-size: 24px;
                margin: 25px 0;
                list-style: none;
                padding: 0;
                max-width: 600px;

                a {
                    svg {
                        float: right;
                        height: 48px;
                    }
                }

            }
        }

        @include media-breakpoint-down(md) {
            ul li a svg {
                height: 48px;
                margin: 0 100px 0 63px;
                float: none;

            }
        }

        .ico {
            display: inline-block;
            width: 49px;
            height: 49px;
            margin-right: 15px;
        }

        .collapse {
            display: flex;

            span.ico svg {
                width: 49px;
                height: 49px;
            }

            .content {
                min-height: 200px;
            }

            .Collapsible {
                padding-top: 3px;
            }


            .Collapsible__contentOuter {
                margin-left: 15px;
            }

            .Collapsible__contentInner {
                a {
                    display: block;
                }
            }
        }

        .caption {
            margin-left: 50px;
            font-size: 18px;
        }

        &.employee {
            .ico svg {
                .do-cls-1 {
                    fill: $color-contrast-white;
                    stroke: $color-contrast-white;
                }

                .do-cls-2 {
                    fill: $color-deep-blue;
                    stroke: $color-contrast-white;
                }

                .sm-cls-1 {
                    fill: $color-deep-blue;
                    stroke: $color-contrast-white;
                }

                .sm-cls-2 {
                    stroke: $color-contrast-white;
                }

                .el-cls-1 {
                    fill: $color-deep-blue;
                }

                .el-cls-2 {
                    stroke: $color-contrast-white;
                }

                .lib-cls-1 {
                    fill: $color-deep-blue;
                }

                .lib-cls-2 {
                    stroke: $color-contrast-white;
                }

                .em-cls-1 {
                    fill: $color-deep-blue;
                }

                .em-cls-2 {
                    stroke: $color-contrast-white;
                }

                .mt-cls-1 {
                    fill: $color-deep-blue;
                }

                .mt-cls-2 {
                    stroke: $color-contrast-white;
                }

                .kb-cls-1 {
                    fill: $color-deep-blue;
                }

                .kb-cls-2 {
                    stroke: $color-contrast-white;
                }
            }

            h1 {
                color: $color-pink;

                &.featured {
                    color: $color-deep-blue;
                }
            }
        }
    }


    .link-logout {
        border: none;
        background: none;
        color: #1070DE;
    }

    h1 {
        font-size: 32px;
        color: $color-deep-blue;
        font-weight: bold;

        &.featured {
            color: $color-pink;
        }
    }


}

@include media-breakpoint-down(md) {
    .page {
        background-position: left 465% bottom 15%;
    }
}